import * as React from "react"
import * as d3 from "d3"
import { fIntG, fFloatG, Colors2 } from "../helper";

const cachecolor = [
  "bg-[#ccc]",
  "bg-[#ffa500]",
];

const getNextColor = colors => {
  let i = 0

  return () => {
    i++
    if (i === colors.length) {
      i = 0
    }
    return colors[i]
  }
}

const Histo3 = ({ idkey }) => {
  const data = [
    {
      date: "2012",

      all: 20922,
      serv: 10781 ,
      // p: '91,3%'
    },
    {
      date: "2013",

      all: 20915,
      serv: 10856,
      // p: '94%'
    },
    {
      date: "2014",

      all: 20141  ,
      serv: 11003,
      p: '54,6%'
    },
    {
      date: "2015",

      all: 20039,
      serv: 10872,
      p: '54,3%'
    },
    {
      date: "2016",

      all: 19949 ,
      serv: 10886 ,
      p: '54,6%'
    },
    {
      date: "2017",

      all: 19911,
      serv: 10709,
      p: '53,8%'
    },
    {
      date: "2018",

      all: 19871 ,
      serv: 11000 ,
      p: '55,4%'
    },
    {
      date: "2019",

      all: 19823,
      serv: 10608,
      p: '53,5%'
    },
    {
      date: "2020",

      all: 19761,
      serv: 9797,
      // p: '95,1%'
    },
    {
      date: "2021",

      all: 19668,
      serv: 9054,
      // p: '95,7%'
    },
  ]

  const margin = { top: 30, right: 30, bottom: 50, left: 100 },
    width = 750 - margin.left - margin.right,
    height = 430 - margin.top - margin.bottom

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`
    document.querySelector(selector).innerHTML = ""
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    const subgroups = ["all", "serv"]
    var groups = d3.map(data, d => {
      return d.date
    })

    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2])

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .attr("transform", "translate(10,0)rotate(-45)")
      .style("text-anchor", "end")

    var y = d3.scaleLinear().domain([0, 21000]).range([height, 0])
    svg.append("g").call(d3.axisLeft(y).tickFormat(fIntG))

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05])

    var color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(Colors2)

    const g = svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", d => {
        return "translate(" + x(d.date) + ",0)"
      })

      .selectAll("rect")
      .data(d => {
        return subgroups.map(key => {
          // if (key == "all")
          //   return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .enter()
      .append("rect")
      .attr("x", d => {
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value)
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", d => {
        return height - y(d.value)
      })
      .attr("fill", d => {
        return color(d.key)
      })
      .exit()
      .data(d => {
        return subgroups.map(key => {
          // if (key == "all")
          // return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .join("text")

      .attr("x", d => {
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value) + 5
      })
      .text(d => fFloatG(d.value))
      .style("font-size", "12px")
      .style("fill", "black")
      .style('font-weight', 'bold')
      .attr("text-anchor", "end")
      .attr("transform", d => {
        return `rotate(-90, ${xSubgroup(d.key) + 10}, ${y(d.value)})`
      })
      // .attr("transform", d => {
      //   return "translate(20,0)"
      // })
      .exit()
      .data(d => {
        return ['p'].map(key => {
          // if (key == "all")
          // return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .join("text")

      .attr("x", d => {
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value)
      })
      .text(d => d.value ? '↑' + d.value : '')
      .style("font-size", "12px")
      .attr("transform", d => {
        return "translate(2,0)"
      })

    // svg.
  }, [idkey])

  const ldata = [
    {
      label: "Количество газифицированных строений МКД",
      color: "",
    },
    {
      label: "Количество газифицированных строений, требующих реконструкции газопровода",
      color: "",
    },
  ]

  return (
    <>
      <div className={`HistoOld-${idkey}`}></div>
      <div className="max-w-4xl m-auto">
        <ul className="list-none pl-0 text-300 my-5 text-sm">
          {ldata.map((el, i) => (
            <li key={i}>
              <span className={`legend-pseudo ${cachecolor[i]}`}></span>
              {el.label}
            </li>
          ))}
          <li>
            <span className="ml-[4px]">↑</span>  Доля газифицированных строений, требующих реконструкции газопровода
          </li>
        </ul>
      </div>
    </>
  )
}

export default Histo3
