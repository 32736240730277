import * as React from "react"
import * as d3 from "d3"
import { fIntG, Colors2, fInt } from "../helper";

const cachecolor = [
  "bg-[#ccc]",
  "bg-[#ffa500]",
];

const getNextColor = colors => {
  let i = 0

  return () => {
    i++
    if (i === colors.length) {
      i = 0
    }
    return colors[i]
  }
}

const Histo4 = ({ idkey }) => {
  const data = [
    {
      date: "2012",

      all: 1834515,
      serv: 534168,
      p: "29,1%",
    },
    {
      date: "2013",

      all: 1830675,
      serv: 434241,
      p: "23,7%",
    },
    {
      date: "2014",

      all: 1825579,
      serv: 404682,
      p: "22,2%",
    },
    {
      date: "2015",

      all: 1814758,
      serv: 404167,
      p: "22,3%",
    },
    {
      date: "2016",

      all: 1810003,
      serv: 364693,
      p: "20,1%",
    },
    {
      date: "2017",

      all: 1807600,
      serv: 336873,
      p: "18,6%",
    },
    {
      date: "2018",

      all: 1805956,
      serv: 315370,
      p: "17,5%",
    },
    {
      date: "2019",

      all: 1799254,
      serv: 289870,
      p: "16,1%",
    },
    {
      date: "2020",

      all: 1792886,
      serv: 201600,
      p: "11,2%",
    },
    {
      date: "2021",

      all: 1785280,
      serv: 251206,
      p: '15,6%'
    },
  ]

  const margin = { top: 70, right: 30, bottom: 50, left: 100 },
    width = 750 - margin.left - margin.right,
    height = 450 - margin.top - margin.bottom

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`
    document.querySelector(selector).innerHTML = ""
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    const subgroups = ["all", "serv"]
    var groups = d3.map(data, d => {
      return d.date
    })

    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2])

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .attr("transform", "translate(10,0)rotate(-45)")
      .style("text-anchor", "end")

    var y = d3.scaleLinear().domain([0, 1_900_000]).range([height, 0])
    svg.append("g").call(d3.axisLeft(y).tickFormat(fIntG))

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05])

    var color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(Colors2)

    const g = svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", d => {
        return "translate(" + x(d.date) + ",0)"
      })

      .selectAll("rect")
      .data(d => {
        return subgroups.map(key => {
          // if (key == "all")
          //   return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .enter()
      .append("rect")
      .attr("x", d => {
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value)
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", d => {
        return height - y(d.value)
      })
      .attr("fill", d => {
        return color(d.key)
      })
      .exit()
      .data(d => {
        return subgroups.map(key => {
          // if (key == "all")
          // return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .join("text")

      .attr("x", d => {
        if (d.value < 550000) {
          return xSubgroup(d.key) + 15
        }

        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value) + 5
      })
      .text(d => fIntG(d.value))
      .style("font-size", "12px")
      .style("fill", d => {
        if (d.value < 550000) {
          return 'black'
        }
      return "black"
      })
      .style("font-weight", "bold")
      .attr("text-anchor", d => {
        if (d.value < 550000) {
          return 'start'
        }
      return "end"
      })
      .attr("transform", d => {
        if (d.value < 550000) {
          return `rotate(-90, ${xSubgroup(d.key) + 10}, ${y(d.value)})`
        }

        return `rotate(-90, ${xSubgroup(d.key) + 10}, ${y(d.value)})`
      })
      // .attr("transform", d => {
      //   return "translate(20,0)"
      // })
      .exit()
      .data(d => {
        return ["p"].map(key => {
          // if (key == "all")
          // return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .join("text")

      .attr("x", d => {
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value)
      })
      .text(d => (d.value ? "↑" + d.value : ""))
      .style("font-size", "12px")
      .attr("transform", d => {
        return "translate(2,0)"
      })

    // svg.
  }, [idkey])

  const ldata = [
    {
      label: "Количество установленных газовых плит в МКД",
      color: "",
    },
    {
      label:
        "Количество газовых плит, подлежащих замене",
      color: "",
    },
  ]

  return (
    <>
      <div className={`HistoOld-${idkey}`}></div>
      <div className="max-w-4xl m-auto">
        <ul className="list-none pl-0 text-300 my-5 text-sm">
          {ldata.map((el, i) => (
            <li key={i}>
              <span className={`legend-pseudo ${cachecolor[i]}`}></span>
              {el.label}
            </li>
          ))}
          <li>
            <span className="ml-[4px]">↑</span>  Доля газовых плит, подлежащих замене
          </li>
        </ul>
      </div>
    </>
  )
}

export default Histo4
