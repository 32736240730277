import * as React from "react";
import * as d3 from "d3";
import { fInt } from "../helper";

const getNextColor = (colors) => {
  let i = 0;

  return () => {
    i++;
    if (i === colors.length) {
      i = 0;
    }
    return colors[i];
  };
};

const locale = d3.formatLocale({
  decimal: ",",
  thousands: "\u00a0",
  grouping: [3],
  currency: ["", ""],
  minus: "\u2212",
  percent: "\u202f%",
});

const DynPlot = ({ idkey }) => {
  const margin = { top: 10, right: 50, bottom: 30, left: 60 },
    width = 750 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom;

  React.useEffect(() => {
    const selector = `.DynPlot-${idkey}`;
    document.querySelector(selector).innerHTML = "";

    const data = [
      {
        date: "2013",
        value: 24.1,
        p: -4,

        x: 2,
        // y: -8,
      },
      {
        date: "2014",
        value: 23.2,
        p: -4,
        x: 10,
      },
      {
        date: "2015",
        value: 21.3,
        p: -8,
        x: -2,
        y: -2,
      },
      {
        date: "2016",
        value: 23.0,
        p: 8,
      },
      {
        date: "2017",
        value: 22.2,
        p: -4,
      },
      {
        date: "2018",
        value: 23.0,
        p: 4,
        x: 2,
      },
      {
        date: "2019",
        value: 22.2,
        p: -3,
        x: 8,
      },
      {
        date: "2020",
        value: 20.8,
        p: -7,
        x: -10,
        y: -2,
      },
      {
        date: "2021",
        value: 24.8,
        p: 19,
      },
    ];

    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Add X axis --> it is a date format
    const x = d3
      .scaleLinear()
      .domain(d3.extent(data, (d) => `${d.date}`))
      .range([0, width]);
    svg
      .append("g")
      .attr("transform", `translate(5, ${height})`)
      .call(d3.axisBottom(x).tickFormat(locale.format(" ")));

    // Add Y axis
    const y = d3.scaleLinear().domain([20, 26]).range([height, 0]);
    svg
      .append("g")
      .attr("transform", `translate(-10,0)`)
      .call(
        d3
          .axisLeft(y)
          .tickSizeInner([-width -20])
          .tickValues([20, 21, 22, 23, 24, 25])
          .tickFormat(fInt)
          .tickPadding(10)
      )
      .selectAll(".tick line")
      .attr("stroke-dasharray", "2,2")
      .attr("color", "rgba(0,0,0,.3)")
      .attr("transform", `translate(-5,0)`)
      ;

    // Add the line
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .curve(d3.curveNatural)
          .x((d) => x(d.date) + 5)
          .y((d) => y(d.value))
      );

    // Add the points
    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .join("circle")
      .attr("class", "myCircle")
      .attr("cx", (d) => x(d.date) + 5)
      .attr("cy", (d) => y(d.value))
      .attr("r", 5)
      .attr("stroke", "#FFA500")
      .attr("stroke-width", 3)
      .attr("fill", "white")
      .exit()
      .data(data)
      .join("text")
      .attr("x", (d) => x(d.date) - 10 + (d.x || 0))
      .attr("y", (d) => y(d.value) - 25 + (d.y || 0))
      .text((d) => `(${d.p}%)`)
      .style("font-size", "12px")
      .style("font-weight", "bold")

      .exit()
      .data(data)
      .join("text")
      .attr("x", (d) => x(d.date) - 6 + (d.x || 0))
      .attr("y", (d) => y(d.value) - 10 + (d.y || 0))
      .text((d) => d.value)
      .style("font-size", "12px")
      .style("font-weight", "bold");
  }, [idkey]);

  const ldata = [
    {
      label: "газопровод высокого давления",
      color: "",
    },
    {
      label: "газопровод среднего давления",
      color: "",
    },
    {
      label: "газопровод низкого давления",
      color: "",
    },
    {
      label: "общая протяженность",
      color: "",
    },
  ];
  const lcolor = [
    "bg-[#383c50]",
    "bg-[#405790]",
    "bg-[#8d8e91]",
    "bg-[#cb953e]",
  ];
  const getLegendColor = getNextColor(lcolor);

  return (
    <>
      <div className={`DynPlot-${idkey}`}></div>
    </>
  );
};

export default DynPlot;
