import * as React from "react";
import * as d3 from "d3";
import { fFloatG, Colors2 } from "../helper";

const cachecolor = ["bg-[#ccc]", "bg-[#ffa500]"];



const Histo6 = ({ idkey }) => {
  const data = [
    {
      date: "2016",

      all: 7465.7,
      serv: 3473.2,
    },
    {
      date: "2017",

      all: 7453.7,
      serv: 3657.03,
    },
    {
      date: "2018",

      all: 7447.4,
      serv: 3609.88,
    },
    {
      date: "2019",

      all: 7459.5,
      serv: 3574.4,
    },
    {
      date: "2020",

      all: 7525.57,
      serv: 2731.83,
    },
    {
      date: "2021",

      all: 7591.43,
      serv: 1645.9,
    },
  ];

  const margin = { top: 70, right: 30, bottom: 50, left: 100 },
    width = 650 - margin.left - margin.right,
    height = 450 - margin.top - margin.bottom;

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`;
    document.querySelector(selector).innerHTML = "";
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const subgroups = ["all", "serv"];
    var groups = d3.map(data, (d) => {
      return d.date;
    });

    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2]);

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .attr("transform", "translate(10,0)rotate(-45)")
      .style("text-anchor", "end");

    var y = d3.scaleLinear().domain([0, 8_000]).range([height, 0]);

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05]);

    var color = d3.scaleOrdinal().domain(subgroups).range(Colors2);

    const g = svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", (d) => {
        return "translate(" + x(d.date) + ",0)";
      })

      .selectAll("rect")
      .data((d) => {
        return subgroups.map((key) => {
          // if (key == "all")
          //   return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", (d) => {
        return xSubgroup(d.key);
      })
      .attr("y", (d) => {
        return y(d.value);
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", (d) => {
        return height - y(d.value);
      })
      .attr("fill", (d) => {
        return color(d.key);
      })
      .exit()
      .data((d) => {
        return subgroups.map((key) => {
          return { key: key, value: d[key] };
        });
      })
      .join("text")

      .attr("x", (d) => {
        if (d.value < 40000) {
          return xSubgroup(d.key) + 20;
        }

        return xSubgroup(d.key);
      })
      .attr("y", (d) => {
        return y(d.value) + 5;
      })
      .text((d) => fFloatG(d.value))
      .style("font-size", "12px")
      .style("fill", (d) => {
        if (d.value < 40000) {
          return "black";
        }
        return "black";
      })
      .style("font-weight", "bold")
      .attr("text-anchor", (d) => {
        if (d.value < 40000) {
          return "start";
        }
        return "end";
      })
      .attr("transform", (d) => {
        return `rotate(-90, ${xSubgroup(d.key) + 15}, ${y(d.value)})`;
      });
  }, [idkey]);

  const ldata = [
    {
      label: "Протяженность газопровода (км)",
      color: "",
    },
    {
      label: "Протяженность обследованного газопровода (км)",
      color: "",
    },
  ];

  return (
    <>
      <div className={`HistoOld-${idkey} max-w-4xl m-auto`}></div>
      <div className="max-w-4xl m-auto">
        <ul className="list-none pl-0 text-300 my-5 text-sm">
          {ldata.map((el, i) => (
            <li key={i}>
              <span className={`legend-pseudo ${cachecolor[i]}`}></span>
              {el.label}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Histo6;
