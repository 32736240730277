import * as React from "react"
import * as d3 from "d3"
import { fIntG, Colors2 } from "../helper";

const cachecolor = [
  "bg-[#ccc]",
  "bg-[#ffa500]",
];

const getNextColor = colors => {
  let i = 0

  return () => {
    i++
    if (i === colors.length) {
      i = 0
    }
    return colors[i]
  }
}


const Histo5 = ({ idkey }) => {
  const data = [
    {
      date: "2012",

      all: 126562,
      serv: 10747,
      p: "8,49%",
    },
    {
      date: "2013",

      all: 125617,
      serv: 10377,
      p: "8,26%",
    },
    {
      date: "2014",

      all: 125526,
      serv: 7856,
      p: "6,26%",
    },
    {
      date: "2015",

      all: 125524,
      serv: 6384,
      p: "5,09%",
    },
    {
      date: "2016",

      all: 125522,
      serv: 6447,
      p: "5,14%",
    },
    {
      date: "2017",

      all: 125521,
      serv: 8903,
      p: "7,09%",
    },
    {
      date: "2018",

      all: 125450,
      serv: 8425,
      p: "6,72%",
    },
    {
      date: "2019",

      all: 125047,
      serv: 7992,
      p: "6,39%",
    },
    {
      date: "2020",

      all: 122997,
      serv: 10241,
      p: "8,33%",
    },
    {
      date: "2021",

      all: 122064,
      serv: 12441,
      p: "10,19%",
    },
  ]

  const margin = { top: 70, right: 30, bottom: 50, left: 100 },
    width = 750 - margin.left - margin.right,
    height = 450 - margin.top - margin.bottom

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`
    document.querySelector(selector).innerHTML = ""
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    const subgroups = ["all", "serv"]
    var groups = d3.map(data, d => {
      return d.date
    })

    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2])

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .attr("transform", "translate(10,0)rotate(-45)")
      .style("text-anchor", "end")

    var y = d3.scaleLinear().domain([0, 130_000]).range([height, 0])
    svg.append("g").call(d3.axisLeft(y).tickFormat(fIntG))

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05])

    var color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(Colors2)

    const g = svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", d => {
        return "translate(" + x(d.date) + ",0)"
      })

      .selectAll("rect")
      .data(d => {
        return subgroups.map(key => {
          // if (key == "all")
          //   return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .enter()
      .append("rect")
      .attr("x", d => {
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value)
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", d => {
        return height - y(d.value)
      })
      .attr("fill", d => {
        return color(d.key)
      })
      .exit()
      .data(d => {
        return subgroups.map(key => {
          // if (key == "all")
          // return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .join("text")

      .attr("x", d => {
        if (d.value < 40000) {
          return xSubgroup(d.key) + 15
        }

        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value) + 5
      })
      .text(d => fIntG(d.value))
      .style("font-size", "12px")
      .style("fill", d => {
        if (d.value < 40000) {
          return "black"
        }
        return "black"
      })
      .style("font-weight", "bold")
      .attr("text-anchor", d => {
        if (d.value < 40000) {
          return "start"
        }
        return "end"
      })
      .attr("transform", d => {
        return `rotate(-90, ${xSubgroup(d.key) + 10}, ${y(d.value)})`
      })
      // .attr("transform", d => {
      //   return "translate(20,0)"
      // })
      .exit()
      .data(d => {
        return ["p"].map(key => {
          // if (key == "all")
          // return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .join("text")

      .attr("x", d => {
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value)
      })
      .text(d => (d.value ? "↑" + d.value : ""))
      .style("font-size", "12px")
      .attr("transform", d => {
        return "translate(2,0)"
      })

    // svg.
  }, [idkey])

  const ldata = [
    {
      label: "Количество установленных проточных водонагревателей",
      color: "",
    },
    {
      label: "Количество проточных водонагревателей, требующих замены в МКД",
      color: "",
    },
  ]
  const lcolor = [
    "bg-[#383c50]",
    "bg-[#405790]",
    "bg-[#8d8e91]",
    "bg-[#cb953e]",
  ]
  const getLegendColor = getNextColor(lcolor)

  return (
    <>
      <div className={`HistoOld-${idkey}`}></div>
      <div className="max-w-4xl m-auto">
        <ul className="list-none pl-0 text-300 my-5 text-sm">
          {ldata.map((el, i) => (
            <li key={i}>
              <span className={`legend-pseudo ${cachecolor[i]}`}></span>
              {el.label}
            </li>
          ))}
          <li>
            <span className="ml-[4px]">↑</span>  Доля проточных
            водонагревателей, требующих замены
          </li>
        </ul>
      </div>
    </>
  )
}

export default Histo5
